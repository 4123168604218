import React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { SiteContextProvider } from "../contexts/site";
import SiteHeader from "./SiteHeader"
import SiteFooter from "./SiteFooter"
import Navigation from "./Navigation"


function Layout({ children, context, openGraph }) {
  const canonicalUrl = () => {
    const baseUrl = `https://www.spinecentre.com.hk`;
    const contextUrl = context.type === 'generic' ? context.localizedUrls[context.locale] : context.permalink
    let url = `${baseUrl}${contextUrl}`
    if (context.hasOwnProperty('currentPage') && context.currentPage !== 1) {
      url += `/${context.currentPage}`;
    }
    return `${contextUrl === '/' ? url.substring(0, url.length - 1) : url}/`;
  }
  const linkTags = () => {
    if (context.hasOwnProperty('numberOfPages')) {
      const pagination = [];
      if (context.hasNextPage) {
        pagination.push({
          rel: "next",
          href: `https://www.spinecentre.com.hk/${context.locale}/back-health-blog/${context.currentPage + 1}`
        })
      }
      if (context.hasPreviousPage) {
        pagination.push({
          rel: "prev",
          href: `https://www.spinecentre.com.hk/${context.locale}/back-health-blog/${context.currentPage - 1}`
        })
      }
      return pagination
    } else {
      return []
    }
  }
  const languageAlternativeUrls = () => {
    if (context.type === 'blog') {
      return [
        {
          hrefLang: 'x-default',
          href: `https://www.spinecentre.com.hk${context.localizedUrls['ch']}`
        },
        {
          hrefLang: context.locale === 'en' ? 'en-HK' : 'zh-Hant-HK',
          href: `https://www.spinecentre.com.hk${context.permalink}/`
        },
      ]
    } else {
      return context.pageId ? [
        {
          hrefLang: 'x-default',
          href: `https://www.spinecentre.com.hk${context.localizedUrls['ch']}`
        },
        {
          hrefLang: 'zh-Hant-HK',
          href: `https://www.spinecentre.com.hk${context.localizedUrls['ch']}`
        },
        {
          hrefLang: 'en-hk',
          href: `https://www.spinecentre.com.hk${context.localizedUrls['en']}`
        }
      ] : []
    }
  }
  const query = graphql`
    query MenuItems {
      ch: firestore {
        settings(locale: "ch") {
          whatsapp
        }
        conditions(locale: "ch") {
          id
          title
          href
          description
          thumbnail
        }
        treatments(locale: "ch") {
          id
          title
          href
          description
          thumbnail
        }
      }
      en: firestore {
        settings(locale: "en") {
          whatsapp
        }
        conditions(locale: "en") {
          id
          title
          href
          description
          thumbnail
        }
        treatments(locale: "en") {
          id
          title
          href
          description
          thumbnail
        }
      }
    }
  `

  return (
    <SiteContextProvider data={context}>
      <Helmet>
        <script src="/fp.js"></script>
      </Helmet>
      <GatsbySeo
        title={context.title}
        description={context.description}
        metaTags={[
          {
            name: 'keywords',
            content: context.keywords
          }
        ]}
        titleTemplate={`%s - ${context.locale === 'ch' ? '香港護脊中心' : 'HK Spine Centre'}`}
        canonical={canonicalUrl()}
        linkTags={linkTags()}
        languageAlternates={languageAlternativeUrls()}
        openGraph={openGraph}
      />
      <JsonLd
        json={
          {
            "@context": "https://schema.org",
            "@type": "Physiotherapy",
            "name": "香港護脊中心 | Hong Kong Spine Centre",
            "image": "https://www.spinecentre.com.hk/_assets/img/logo.svg",
            "@id": "https://www.spinecentre.com.hk",
            "url": "https://www.spinecentre.com.hk",
            "telephone": "+852 2111 9911",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "7/F, Parker House, 72 Queen's Rd",
              "addressLocality": "Hong Kong",
              "postalCode": "00000",
              "addressCountry": "HK"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 22.2828333,
              "longitude": 114.1554891
            },
            "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "10:00",
              "closes": "13:30"
            }, {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "15:00",
              "closes": "18:00"
            }, {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Saturday",
              "opens": "10:00",
              "closes": "13:00"
            }],
            "sameAs": "https://www.facebook.com/hkspinecentre/"
          }
        }
      />
      <div className="fixed top-0 w-screen z-50">
        <SiteHeader />
        <StaticQuery
          query={query}
          render={data => (
            <Navigation conditions={data[context.locale]["conditions"]} treatments={data[context.locale]["treatments"]} />
          )}
        />
      </div>
      <main className="pt-52 sm:pt-40">
        {children}
      </main>
      <StaticQuery
        query={query}
        render={data => (
          <SiteFooter conditions={data[context.locale]["conditions"]} treatments={data[context.locale]["treatments"]} />
        )}
      />
    </SiteContextProvider>
  )
}

export default Layout
